<template>
    <div v-if="show" class="number">
         <div v-if="isMachineChoose" class="number_info auto_wrap">
             <div class="number_info_wrap">
                <h3>自动多期机选</h3>
                <p v-if="inWhiteList">此玩法<span>不支持选号</span>，可<span>选择注数</span>，点击<span data-v-6eb48c43="">生成短信后</span>，完成选号。机选号码将通过短信发送至您手机，请查收！</p>
                <p v-else>此玩法<span>不支持选号</span>，可<span>选择注数</span>，具体指令可在首页->短信指令页面查看。</p>
             </div>
         </div>
         <div class="number_recard">
            <ul class="number_recard_tab" v-if="tab.length > 1">
                <li 
                    :class="{'red': tabActive == index}" 
                    v-for="(item, index) in tab" 
                    @click="tabActive = index"
                    :key="index">
                    {{item.label}}
                </li>
            </ul>
            <div class="number_recard_confirm">
                <div class="number_recard_confirm_infos">
                    <div class="number_recard_confirm_infos_left">
                    <text>投</text>
                    <div>
                        <text class="number_recard_confirm_infos_left_icon icon_minus" @click="minus"></text>
                        <input v-model="innerInput" @input="inputEvent" @blur="inputEvent" type="text" />
                        <text class="number_recard_confirm_infos_left_icon icon_plus" @click="plus"></text>
                    </div>
                    <text>{{unit}}</text>
                    </div>
                    <div class="number_recard_confirm_infos_right">
                    <text>{{times}}倍 </text>
                    <text>{{pour}}注 </text>
                    <text class="red">共{{times*pour*2 || 0}}元</text>
                    </div>
                </div>
                <!-- 生成短信 -->
                <div class="number_recard_confirm_genmessage">
                    <text>{{inWhiteList ? '生成短信后点击发送即可完成选号！' : '详情可查看短信指令页面~'}}</text>
                    <text v-show="inWhiteList" class="red_white_solid button_middle" @click="genMessages">生成短信</text>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, reactive, toRefs } from '@vue/runtime-core'
import { isSevenChooseFive, sendMessage, sleep, toast } from '../utils/util'
export default {
    name: 'bottomCalc',
    props: {
        times: {
            type: Number,
            default: 1,
        },
        inWhiteList: {
            type: Boolean,
            default: false
        },
        pour: {
            type: Number,
            default: 1
        },
        modifyType: {
            type: String,
            default: 'times'
        },
        tab: {
            type: Array,
            default: () => {
                return []
            }
        },
        gameId: {
            type: Number,
            default: 0
        },
        show: {
            type: Boolean,
            default: true
        }
    },
    setup (prop, context) {
        const state = reactive({
            innerInput: 0,
            maxPours: 100,
            maxTimes: 50,
            tabActive: 0,
            isInputting: false,
        })
        // 是否机选 机选多注，自选多倍
        const isMachineChoose = prop.modifyType == 'pour'
        // 单位
        const unit = isMachineChoose ? '注' : '倍'
        const money = computed(() => {
            return prop.times*prop.pour*2
        }) 
        let maxNum
        // 初始化数据
        function initValue() {
            state.innerInput = prop[prop.modifyType]
            console.log(prop.gameId)
            state.maxTimes = isSevenChooseFive(prop.gameId) ? 50 : 50
            maxNum = isMachineChoose ? state.maxPours : state.maxTimes
        }
        initValue()
        function minus() {
            state.innerInput = state.innerInput - 1 < 0 ? state.innerInput : state.innerInput - 1
            poursHandle('changeValue', {prop: prop.modifyType, value: state.innerInput})
        }
        function plus() {
            const maxValue =prop.modifyType == 'times' ? state.maxTimes : state.maxPours
            state.innerInput = state.innerInput + 1 > maxValue ? maxValue : state.innerInput+1
            poursHandle('changeValue', {prop: prop.modifyType, value: state.innerInput})
        }
        // 输入事件
        async function inputEvent() {
            poursHandle('changeValue', {prop: prop.modifyType, value: parseInt(state.innerInput)})
        }
        // 生成短信
        async function genMessages() {
            const value = parseInt(state.innerInput)
            console.log(value)
            if (value < 1) {
                toast(`请至少选择一${unit}`)
                await sleep(1000)
                state.innerInput = 1
                poursHandle('changeValue', {prop: prop.modifyType, value: parseInt(state.innerInput)})
            } else if (value > maxNum) {
                toast(`最大不能超过${maxNum}${unit}`)
                await sleep(1000)
                state.innerInput = maxNum
                poursHandle('changeValue', {prop: prop.modifyType, value: parseInt(state.innerInput)})
            } else {
                const currentTab = prop.tab[state.tabActive]
                const _content = `${currentTab&&currentTab.orderNumber}#${state.innerInput}`
                if(isMachineChoose) {
                    sendMessage(_content, money.value)
                }
                context.emit(`${isMachineChoose ? 'machineChoose' : 'clientChoose'}`, `${isMachineChoose ? _content : state.innerInput}`)
            }

        }
        function poursHandle(eventName, value) {
            context.emit(eventName, value)
        }
        return {
            ...toRefs(state),
            poursHandle,
            unit,
            minus,
            plus,
            genMessages,
            isMachineChoose,
            inputEvent
        }
    }
}
</script>
<style lang='scss' scoped>
.number {
    &_info {
        &_wrap {
            background: #fff;
            margin: 330px 20px 0 20px;
            padding: 10px;
            border-radius: $box-radius-small;
            position: relative;
            border: solid $theme-dark 1px;
            &::after{
                content: '';
                position: absolute;
                width: 30px;
                height: 70px;
                background: url('../assets/imgs/const_invest_arrow.png') center center no-repeat;
                background-size: contain;
                bottom: -70px;
                left: 50%;
                transform: translateX(-50%);
            }
            h3 {
                font-size: $font-middle;
                text-align: center;
                padding-bottom: 5px;
            }
            p {
                padding: 5px 0;
                text-indent: 2em;
                line-height: 1.6em;
                span {
                    color: $theme-dark;
                }
            }
        }
    }
}
.number_recard {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    &_tab {
        display: flex;
        font-size: $font-small;
        li {
            background: #fff;
            width: 50%;
            text-align: center;
            padding: 13px 0 8px 0;
            &:first-child{
                border-radius: 0 $box-radius-big 0 0;
                margin-right: 5px;
            }
            &:last-child {
                border-radius: $box-radius-big 0 0 0;
                margin-left: 5px;
            }
        }
    }
    &_confirm {
        background: #fff;
        box-shadow: 1px -1px 2px #efefef;
        z-index: 10;
        position: relative;
        &_infos{
            display: flex;
            justify-content: space-between;
            border-bottom: solid #efefef 1px;
            padding-bottom: 10px;
            padding: 5px 10px;
            font-size: $font-big;
            &_left {
                display: flex;
                align-items: center;
                input {
                width: 50px;
                border-left: solid #efefef 1px;
                border-right: solid #efefef 1px;
                text-align: center;
                padding: 2px 0;
                height: 20px;
                }
                &_icon {
                display: inline-block;
                width: 15px;
                height: 15px;
                /* border: solid #efefef 1px; */
                padding: 3px;
                }
                &>div {
                display: flex;
                justify-content: center;
                align-items: center;
                border:solid #efefef 1px ;
                margin: 0 10px;
                }
                .icon_minus {
                background: url('../assets/imgs/minus_gray.png') center center no-repeat;
                background-size: 60%;
                border-right: none;
                }
                .icon_plus {
                background: url('../assets/imgs/plus_gray.png') center center no-repeat;
                background-size: 60%;
                border-left: none;
                }
            }
            }
        &_genmessage {
                display: flex;
                justify-content: space-between;
                align-content: baseline;
                align-items: baseline;
                padding: 5px 10px;
            }
        }
    }
</style>