<template>
  <div class="choose_number">
    <!-- 机选多期投注 -->
    <BottomCount 
        :pour="pour"
        :modifyType='machineChooseAuto ? "pour" : "times"'
        :times="times"
        :tab="lotteryTabs"
        :inWhiteList="inWhiteList"
        :gameId="gameId"
        :show="isTermlyLottery != undefined"
        @clientChoose="clientChoose($event)"
        @changeValue="changeValue($event)"/>
    <!-- 提示弹出框 -->
    <dialog-box 
      title="系统提示" 
      @cancel="cancel"
      @completed="completed"
      :content="content"
      :showDialog="showDialog" 
      :buttons="authorButton"></dialog-box>
    <!-- 头部 -->
   <div class="choose_number_head">
     <div class="choose_number_head_text" @click="showModeChangeDialog">{{modeLabels[modeIndex]}}<div v-if="modeLabels.length > 1" class="tangle"></div></div>
     <div class="choose_number_head_choose" v-show="showModesChoose && modeLabels.length > 1">
       <text 
        class="choose_number_head_choose_button" 
        :class="{'button_choosed': index==modeIndex}" 
        @click="changeMode(index)"
        v-for="(text, index) in modeLabels" 
        :key="text+index">
         {{text}}
       </text>
     </div>
   </div>
   <!-- 历史开奖号码 -->
   <OpenBonusHistory :gameId="gameId"/>
   <!-- 投注号码选择 -->
   <div class="choose_number_choose">
     <div class="machine_choose" v-show="currentMode.machineChoose" @click="machineChooseFunc">机选</div>
     <div class="choose_number_items">
       <p class="main_tip" v-if="mainTip && mainTip.length > 0">{{mainTip}}</p>
       <div class="choose_number_item" v-for="(ballsInfo, index) in currentMode.balls" :key="index">
         <div class="choose_number_item_tip">{{ballsInfo.label}}</div>
         <div class="choose_number_item_ball" v-if="!currentMode.chooseAll">
           <Ball 
            v-for="(ballInfo, childIndex) in ballsInfo.showBalls" 
            :isChoose="ballInfo.isChoosed"
            :value="ballInfo.value"
            :color="ballsInfo.color"
            :key="index+childIndex" 
            @ballClick="ballClick(index, childIndex)"/>    
         </div>
         <div v-else class="choose_number_item_ball choosed_content"  
            v-for="(ballInfo, childIndex) in ballsInfo.showBalls" 
            :class="{'choosed_content_active': ballInfo.isChoosed}"
            :isChoose="ballInfo.isChoosed"
            :value="ballInfo.value"
            :color="ballsInfo.color"
            :key="index+childIndex" 
            @click="ballClick(index, childIndex)">
            <span v-for="(value, valueIndex) in currentMode.chooseAll" 
            :style="spanStyle(currentMode.chooseAll.length)"
            :key="valueIndex">
              {{value}}
            </span>
         </div>
       </div>
     </div>
   </div>
   <!-- 用户选择号码 -->
   <ul v-if="showNumberArr" class="choose_number_numbers">
     <li 
        :class="{'active': index == numberArrIndex}" 
        v-for="(item, index) in numberArr" 
        @click="numberArrIndex = index"
        :key="index">
       {{item}}
     </li>
   </ul>
   <!-- 底部确认框 -->
   <div class="choose_number_confirm">
     <div class="rule_item" v-if="rule&&rule.length > 0"><p>{{rule}}</p></div>
     <div>
        <div class="choose_number_confirm_left">
          <text class="choose_number_confirm_left_item remove"  @click="remove"></text>
          <text class="choose_number_confirm_left_item">{{pour}}注</text>
          <text class="choose_number_confirm_left_item">{{pour*2}}元</text>
        </div>
        <div v-if="inWhiteList" class="choose_number_confirm_right red_white_solid button_middle" @click="confirm">
          确定
        </div>
     </div>
   </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs, inject, onMounted } from 'vue'
import { toast, 
         jumpTo, 
         getQueryStr, 
         changeBallsStatus, 
         machineChoose, 
         getUserInfo,
         saveLotteryData, 
         goTop,
         canPour,
         getVisitor,
         setModeIndex,
         getModeIndex,
         getBetNumber,
         labelMap,
         sendMessage,
         getRepeatValueAndCount,} from '../utils/util'
import { calcPour } from '../utils/calc-pour'
import Ball from '../components/Ball.vue'
import OpenBonusHistory from '../components/home/OpenPriceHistory.vue'
import BottomCount from '../components/bottomCount.vue'
import { openBonnusTime } from '../utils/api'
import { auotLotteryNotTimes, chooseNumberArrMachine, gameIDMap } from '../common-data'
import { onBeforeRouteLeave } from 'vue-router'
export default {
  name: 'ChooseNumber',
  components: { Ball, OpenBonusHistory,BottomCount},
  setup () {
      const state = reactive({
        modes: [],
        modeLabels: [],
        balls: [],
        tips: [],
        machineChoose: false,
        modeIndex: 0,
        showModesChoose: false,
        pour: 0,
        // gameId: 11,
        userInfo: {},
        content: '中了大奖实名认证才能领取哦，系统检测到您尚未实名，请点击下方按钮前往实名。',
        authorButton: [
           {label: '取消', eventName: 'cancel'},
           {label: '去实名', eventName: 'completed'}
        ],
        showDialog: false,
        pourHandle: false,
        // 多期投注数据
        // pours: 1,
        times: 1,
        lotteryTabs: [],
        numberArr: [],
        numberArrIndex: 0,
        inWhiteList: false
      })
      onBeforeRouteLeave((to, from, next) => {
        console.log(to, from)
        if(to.path.indexOf('lotterylist') > -1) {
          setModeIndex(state.modeIndex)
        } else {
          setModeIndex(0)
        }
        next()
      })

      // 计算获取当前数据
      const currentMode = computed(() => {
        return state.modes[state.modeIndex]
      })

      const mainTip = computed(() => {
        return currentMode.value.tip
      })
      const rule = computed(() => {
        return currentMode.value.rule
      })

      // 计算是否显示总提示
      const showTips = computed(() => {
        return state.tips.length > 0
      })
      // 
      // 去实名
      function completed() {
        jumpTo(`/fillInfo?name=${state.userInfo.realName}&IdCard=${state.userInfo.cardNumber}`)
      }

      // 取消
      function cancel() {
        state.showDialog = false
      }

      // 机选
      function machineChooseFunc() {
        machineChoose(currentMode.value)
        calPourNum()
      }

      // 玩法ID
      const gameId = parseInt(getQueryStr('gameId') || state.gameId)
      // 玩法信息
      const _gameInfo = gameIDMap.get(parseInt(gameId))
      // 当前模式下标
      const modeIndex = getQueryStr('modeIndex') || getModeIndex() || 0 
      // 展示球球的下标
      const showBallsIndex = getQueryStr('showBallsIndex')

      // 是否为多期投注
      const isTermlyLottery = getQueryStr('machineAuto')
      // // 是否机选多期投注
      const machineChooseAuto = isTermlyLottery == 'true'

      // 是否显示号码
      const showNumberArr = computed(() => {
        console.log(123)
        return state.modeLabels[state.modeIndex] == '组选3-单式' && isTermlyLottery == 'false' 
      })

      function initData () {
        document.title = _gameInfo.name
        const infos = inject('chooseNumberInfo').getData(gameId)
        if (isTermlyLottery) { // 如果是自动多期投注 machineChooseAuto == 'true' || 'false' // 机选多期投注|| 自选多期投注
          state.modes = machineChooseAuto ? infos.modes.slice(0, 1) : infos.modes.filter(ele => ele.autoLotteryNumber)
          state.lotteryTabs = machineChooseAuto ? chooseNumberArrMachine.get(gameId) : []
          if (machineChooseAuto) {
            state.modes[0].balls.forEach(ballLine => {
              ballLine.showBalls.forEach(ele => ele.isChoosed = false)
            })
          }
        } else {
           state.modes = infos.modes
        }
        state.modeLabels = state.modes.map(ele => ele.label)
        state.modeIndex = modeIndex
        if (showBallsIndex && currentMode.value.betNumberIndexArr && currentMode.value.betNumberIndexArr[showBallsIndex]) {
            const selectedBalls = currentMode.value.betNumberIndexArr[showBallsIndex]
            changeBallsStatus(selectedBalls, currentMode.value)
        }
        calPourNum()
      }
      initData()

      async function getDate() {
        try {
          const data = await openBonnusTime(gameId)
          if (data.code == 0) {
            const date = data.data
            if(date) {
              state.pourHandle = canPour(date.startTime, date.endTime)
            }
          }
        } catch(e) {
          console.error(e)
        }
      }
      getDate()
       // 获取注数
      function calPourNum() {
        state.pour = calcPour(currentMode.value, gameId)
        if (showNumberArr.value && state.pour > 0) {
          let _arr = getBetNumber(currentMode.value)
          _arr.forEach((ele, index) => {
            state.numberArr[index] = ele.redBalls.join('')
          })
          state.pour = 1
        }
      }

      // 复制showBalls
      function copyShowBalls(source, origin) {
        origin.forEach((ele, index) => {
          const sourceEle = source[index]
          ele.showBalls.forEach((_ele, _index) => {
            _ele.isChoosed = sourceEle.showBalls[_index].isChoosed
          })
        })
      }

      // 可切换彩种
      function canChangeLottery() {
        const arr = [11, 23, 24]
        return arr.indexOf(parseInt(gameId)) > -1
      }

      // 是否为复式
      function isMulti(index, isPlus = true) {
        const _currentMode = currentMode.value

        const isTrue = _currentMode.balls.some((ele, _index) => {
          const len = ele.showBalls.filter(ball => ball.isChoosed).length + (_index == index ? isPlus ? 1 : -1 : 0)
          return len > (ele.multiMin || ele.min) - (gameId == 11 ? 0 : 1)
        })
        return isTrue
      }

      // 球球点击事件
      function ballClick(index, childIndex) {
        const ballsInfo = currentMode.value.balls[index], showBalls = ballsInfo.showBalls
        const max = ballsInfo.max, len = showBalls.filter(ele => ele.isChoosed).length
        let ballInfo = showBalls[childIndex]
        // 判断是否包含重复约束
        const repeatLen = currentMode.value.repeat
        if( repeatLen != void 0 && !ballInfo.isChoosed) {
          const len = repeatLen || 0
          const redBalls = []
          currentMode.value.balls.forEach((ele, _index) => {
            if (_index != index) {
              ele.showBalls.forEach(value => {
                if(value.isChoosed) {
                  redBalls.push(value.value)
                }
              })
            }
          })
          const _arr = [...redBalls, ballInfo.value]
          const {countMap} = getRepeatValueAndCount(_arr)
          if (len > 0 && countMap.has(len+1)) {
            const str = len == 1 ? '不能选择重复号码' : `只能选择${len}个重复号码`
            toast(str)
            return
          }
        }
        // 修改选中球球颜色
        
        if (canChangeLottery() && !ballInfo.isChoosed && isMulti(index, true) && state.modeIndex == 0) {
          copyShowBalls(state.modes[0].balls, state.modes[1].balls)
          state.modeIndex = 1
          ballInfo = state.modes[1].balls[index].showBalls[childIndex]
        } else if (canChangeLottery() && ballInfo.isChoosed && !isMulti(index, false) && state.modeIndex == 1) {
          copyShowBalls(state.modes[1].balls, state.modes[0].balls)
          state.modeIndex = 0
          ballInfo = state.modes[0].balls[index].showBalls[childIndex]
        } else if (len > max-1 && !ballInfo.isChoosed) {
          let index = -1 
          showBalls.forEach((ele, _index) => {
            if (ele.isChoosed) {
              index = _index
            }
          })
          showBalls[index].isChoosed = false
        } 
        ballInfo.isChoosed = !ballInfo.isChoosed
        // 计算注数
        calPourNum()
      }
      // 模式修改
      function changeMode(index) {
        state.showModesChoose = false
        state.modeIndex = index
        calPourNum()

      }

      // 模式选择框切换
      function showModeChangeDialog () {
        state.showModesChoose = state.showModesChoose == true ?
          false : state.modeLabels.length > 0 ? true : false
      }

      // 判断是否符合规则
      function normalRule() {
        let canLottery = true  
        currentMode.value.balls.forEach(ballLine => {
          const choosedLen = ballLine.showBalls.filter(ele => ele.isChoosed)
          canLottery = ballLine.min == choosedLen.length
        })
        return canLottery
      }

      // 确定选号
      function confirm() {
        if (!state.pourHandle) {
          toast('不在奖期内，不能投注')
          return
        }
        const value = currentMode.value
        if (state.pour > 0) {
          saveLotteryData(value, showBallsIndex, state.pour)
          jumpTo(`/lotterylist?gameId=${gameId}&modeId=${state.modeIndex}`)
        } else {
          if (normalRule()) {
            toast('不符合选号规则')
          } else {
            toast(`${value && value.tips ? value.tips : '请至少选择一注号码'}`)
          }
        }
      }
      // 加载数据
      async function loadData() {
        try {
          const result = await getUserInfo(true)
          state.userInfo = result
          state.inWhiteList = result && result.open == 1
        } catch(e) {
          console.error(e)
        }
      }
      function initJudge() {
        const value = getVisitor()
        if (value == false || value == 'false') {
          loadData()
        }
      }
      // 删除选择
      function remove() {
        currentMode.value.balls.forEach(ballsLine => {
          ballsLine.showBalls.forEach(ball => {
            ball.isChoosed = false
          })
        })
        calPourNum()
      }
      onMounted(() => {
        goTop()
      })
      // 拖拉机&猜三同样式
      function spanStyle(num) {
        return {
          padding: `${num > 10 ? '10px 9px' : '10px 22px'}`
        }
      }
     initJudge()
      /////////// 多期投注函数 //////////
      function changeValue(prop) {
        state[prop.prop] = prop.value
      }
      // 用户自选多期投注
      function clientChoose(value) {
        console.log(value)
        const orderNumber = currentMode.value.autoLotteryNumber
        let number = getBetNumber(currentMode.value)
        // number = number[0]
        let numberBlue = []
        number[0].blueBalls.forEach(ele => 
          {
            numberBlue.push(labelMap.get(ele) || ele)
          })
        if (state.pour < 1) {
          toast('请至少选择一注')
          return
        }
        const isTimes = auotLotteryNotTimes.indexOf(orderNumber) == -1
        if (!isTimes && value > 1) {
          toast('只能选择1倍, 请修改倍数为1')
          return
        }
        const isSplit = currentMode.value.split
        const redArr = showNumberArr.value ? number[state.numberArrIndex].redBalls.join('') : number[0].redBalls.join('')
        const content = orderNumber + '#' + redArr + `${numberBlue.length > 0 ? (isSplit ? '#' : '') + numberBlue.join('') : ''}` + `${isTimes?'#'+ value : ''}`
        // console.log(content)
        sendMessage(content, state.pour*state.times*2)
      }
      return {
        ...toRefs(state),
        currentMode,
        showTips,
        ballClick,
        changeMode,
        showModeChangeDialog,
        confirm,
        machineChooseFunc,
        completed,
        cancel,
        remove,
        mainTip,
        rule,
        machineChooseAuto,
        spanStyle,
        ////// 多期投注函数
        changeValue,
        clientChoose,
        isTermlyLottery,
        showNumberArr,
        gameId
      }
    }
}
</script>

<style lang=scss>
/* @import '../../style/index.scss'; */
.tangle {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
  margin-left: 5px;
}
.auto_wrap {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#000, 0.3);
  z-index: 3;
}
.choose_number {
  padding: 43px 0 75px 0;
  &_items {
    .main_tip {
      &:first-child{
        padding: 27px $margin-big 7px $margin-big;
      }
    }
  }
  &_choose {
    position: relative;
    .machine_choose {
      position: absolute;
      width: 52px;
      padding: 1px 0;
      background: $theme-dark;
      color: #fff;
      border-radius: $box-radius-small;
      text-align: center;
      right: 15px;
      top: 30px;
      font-size: $font-small;
      &::before{
        content: '';
        width: 30px;
        height: 30px;
        background: url('../assets/imgs/machine_pick_icon.png') center center no-repeat;
        background-size: contain;
        position: absolute;
        left: -18px;
        top: -6px;
      }
    }
  }
  &_head {
    text-align: center;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    &_text {
      color: #fff;
      /* background: $theme-linear-color; */
      background: $theme-dark;
      font-size: $font-big;
      line-height: 2.5em;
      position: relative;
    }
    &_choose {
      background: #fff;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: $margin-big*1.5 0 $margin-big 0;
      z-index: 2;
      &::after{
        content: '';
        position: fixed;
        background: rgba(0, 0, 0, 0.2);
        top: 60px;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      &_button {
        border:solid 1px;
        color: $theme-dark;
        margin: 0 $margin-big 10px 0;
        font-size: $font-middle;
        width: 100px;
        padding: 8px 0;
        border-radius: $box-radius-small;
        &:nth-child(1n) {
          margin-left: $margin-big;
        }
      }
      .button_choosed {
        color: #fff;
        background: $theme-dark;
      }
    }
  }
  &_item {
     .choosed_content {
      border: solid #efefef 1px;
      border-radius: $box-radius-small;
      background: #fff;
      color: $theme-dark;
      display: flex;
      flex-wrap: wrap;
    }
    .choosed_content_active {
      color: #fff;
      background: $theme-dark;
    }
    padding: $margin-big;
    &:first-child {
      padding-top: 25px;
    }
    .ball {
      margin: 0 13px 13px 0;
      width: 37px;
      height: 37px;
      &:nth-child(7n) {
        margin-right: 0;
      }
    }
    &_ball {
      display: flex;
      flex-wrap: wrap;
    }
    &_tip {
      padding-bottom: 16px;
      padding-top: 4px;
    }
  }
  &_numbers {
    display: flex;
    justify-content: space-between;
    text-align: center;
    position: fixed;
    bottom: 100px;
    left: 10px;
    right: 10px;
    li {
      background: #fff;
      color: #000;
      width: 48%;
      border-radius: $box-radius-small;
      padding: 10px 0;
    }
    .active {
      border: $theme-dark 1px solid;
      color: $theme-dark;
      background: #ffe9e9;
      position: relative;
      &::after{
        position: absolute;
        bottom: -1px;
        right: -1px;
        height: 20px;
        width: 20px;
        content: '';
        background: url('../assets/imgs/tangle-icon.png') bottom right no-repeat;
        background-size: 15px;
      }
    }
  }
  &_confirm {
    font-size: $font-big;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    &>div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px $margin-big;
    }
    .rule_item {
        font-size: $font-less;
        color: $theme-dark;
        padding: 8px 5px 8px 8px;
        border-bottom: $line-gray 1px solid;
      }
    &_left {
      display: flex;
      align-items: center;
      &_item {
        display: inline-block;
        padding: 0 10px 0 0;
        &:first-child {
          width: 20px;
          height: 20px;
          background: url('../assets/imgs/trash_can_gray.png') center center no-repeat;
          background-size: 20px;
          border-right: solid $line-gray 2px;
          padding-right: 15px;
        }
        &:nth-child(2) {
          padding-left: 10px;
        }
      }
    }
    &_right {
        color: #fff;
        background: $theme-dark;
        padding: 8px 25px;
        border-radius: $box-radius-small;
    }
  }
}
</style>
