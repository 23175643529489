<template>
    <div class="open_class gray-dark font-middle">
        <p v-if="newDate" class="open_class-head">
            <span>第{{newDate.issueName}}期 {{newDate.endTimeFormat}}({{newDate.endTimeDayOfWeek}})截止投注
        </span></p>
        <p class="open_class-head">
            <span>第{{line1 && line1.issueName}}期</span>
            <span 
                v-if="lineOthers.length > 0"
                class="draw-icon fr" 
                :style="{transform: showAll ? 'rotate(90deg)' : 'rotate(-90deg)'}"
                @click="showAll = !showAll"></span>
            <span>开奖:</span>
            <span v-for="(red, index) in line1 && line1.red" :key="index">{{red}}</span>
            <span v-for="(blue, index) in line1 && line1.blue" :key="'0'+index">{{blue}}</span>
        </p>
        <div class="other-price-content" :style="{maxHeight: showAll ? '' : '0px'}"  v-if="priceNumber.length > 0">
            <p v-for="(row, rowIndex) in lineOthers" :key="rowIndex">
                <span> 第{{row.issueNameShort}}期</span>
                <span class="red" v-for="(red, index) in row.red" :key="index">{{red}}</span>
                <span class="blue" v-for="(blue, index) in row.blue" :key="'0'+index">{{blue}}</span>
                <!-- <span 
                :class="isBlue && (colIndex === row.winCode.split(',').length-1) ? 'blue' : 'red'"
                v-for="(col, colIndex) in row.winCode.split(',')" 
                :key="rowIndex + colIndex">{{col}}</span> -->
            </p>
        </div>
    </div>
</template>
<script>
import { reactive, toRefs } from '@vue/reactivity'
import {wincodeList, openBonnusTime} from '../../utils/api'
import { computed } from '@vue/runtime-core'
import { getQueryStr } from '../../utils/util'
export default {
    name: 'openPriceData',
    props: {
        // 开奖号码
        // 游戏ID
        gameId: {
            type: Number,
            default: 11
        },
    },
    setup(props) {
        const state = reactive({
            priceNumber: [],
            showAll: false,
            issueName: '20201745',
            appEndTime: '1245452',
            newDate: {}
        })
        const line1 = computed(() => {
            return state.priceNumber && state.priceNumber[0]
        })
        const lineOthers = computed(() => {
            return state.priceNumber && state.priceNumber.slice(1, 6)
        })
        const isBlue = computed(() => {
            let tempArray = ['61', '24', '11']
            return tempArray.indexOf(props.gameId) > -1
        })
        const gameId = parseInt(getQueryStr('gameId')) || 11
        async function loadData() {
            try {
                const date = await openBonnusTime(gameId)
                state.newDate = date.data
                const numbers = await wincodeList(gameId, 6, false)
                initData(numbers.data)
            } catch(e) {
                console.error(e)
            }
        }
        function initData(data) {
            data.forEach(ele => {
                let wincode = ele.winningCode.split('#')
                state.priceNumber.push({
                    red: wincode[0] && wincode[0].split(','),
                    blue: wincode[1] && wincode[1].split(','),
                    issueName: ele.issueName,
                    issueNameShort: ele.issueName
                })
            })
        }
        loadData()
        return {
            ...toRefs(state),
            line1,
            lineOthers,
            isBlue
        }
    }
}
</script>
<style scoped lang="scss">
.open_class {
    font-size: $font-small;
    color: $gray-dark;
    .open_class-head {
        padding: $margin-big;
        border-bottom: solid rgba(#ccc, 0.5) 1px;
        background: rgba(#fff, 1);
        position: relative;
        &>span {
            padding: 0 2px;
            display: inline-block;
            line-height: 0.9em;
            &:last-child {
                border-left: solid rgba(#ccc, 0.8) 1px;
                padding-left: 4px;
                margin-left: 2px;
            }
            &:first-child {
                padding-right: 2px;
                // padding-top: 2px;
                margin-left: 2px;
                border-left: solid $theme-dark 3px;
                padding-left: 5px;
            }
        }
        .draw-icon {
            width: 15px;
            height: 15px;
            background: url('../../assets/imgs/arrow_right_gray.png') center center no-repeat;
            background-size: contain;
            transition: transform 0.5s;
            position: absolute;
            right: 10px;
            top: 30%;
            transform: translateY(50%);
        }
    }
    .other-price-content {
        overflow: hidden;
        max-height: 300px;
        transition: max-height 0.5s;
        p {
            padding: 6px $margin-big;
            &:nth-child(2n) {
                background: rgba(#fff, 1);
            }
            &:nth-child(2n-1) {
                background: #efefef;
            }
            span {
                padding: 0 0.1rem;
                &:first-child {
                    position: relative;
                    margin-right: 10px;
                    &::after {
                        display: inline-block;
                        content: '';
                        position: absolute;
                        width: 2px;
                        top: -8px;
                        bottom: -8px;
                        right: -7px;
                        background: #e0e0e0;


                    }
                    &::before {
                        display: inline-block;
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background: #e0e0e0;
                        right: -9px;
                        top: 4px;
                        z-index: 9;
                    }
                }
            }
        }
    }
}
</style>